.my-name {
    font-size: 2rem;
  }
  
  @media screen and (max-width: 767px) {
    .my-name {
      font-size: 1.5rem;
    }
  }
  
  